import {
  createAction,
  buildPaginationRequestParams,
  urijs,
  ENTITY_TYPE_POSTS,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getQueryLocale } from '../selectors/locale-selectors';
import { getCursor, getPageSize } from '../selectors/pagination-selectors';
import { getTotalResults } from '../services/pagination';
import { normalizePostEntities } from '../services/post-utils';

export const FETCH_ARCHIVE_REQUEST = 'archive/FETCH_REQUEST';
export const FETCH_ARCHIVE_SUCCESS = 'archive/FETCH_SUCCESS';
export const FETCH_ARCHIVE_FAILURE = 'archive/FETCH_FAILURE';

export const fetchArchiveRequest = createAction(FETCH_ARCHIVE_REQUEST);
export const fetchArchiveFailure = createAction(FETCH_ARCHIVE_FAILURE);
export const fetchArchiveSuccess = createAction(
  FETCH_ARCHIVE_SUCCESS,
  (payload, meta) => payload,
  (payload, meta) => meta,
);

const getParams = ({ state, page, defaultPageSize }) => {
  const cursor = getCursor(state, ENTITY_TYPE_POSTS);
  const pageSize = getPageSize(state, {
    overrideSettingsPageSize: defaultPageSize,
    section: SECTION_CATEGORY,
  });
  return buildPaginationRequestParams(page, pageSize, cursor);
};

export const fetchArchive =
  (year, month, page = 1, defaultPageSize) =>
  async (dispatch, getState, { request, wixCodeApi }) => {
    dispatch(fetchArchiveRequest({ year, month, page }));
    const path = `/_api/posts/archive/${year}/${month}`;

    const params = getParams({
      state: getState(),
      page,
      defaultPageSize,
    });
    params.timeZone = wixCodeApi.site.timezone;
    const lang = getQueryLocale(getState());
    if (lang) {
      params.lang = lang;
    }
    try {
      // HACK: const promise = request.metered('/_api/posts/archive')(uri(path).query({ ...params }), { parseHeaders: true });
      const response = await request(urijs(path).query({ ...params }), {
        parseHeaders: true,
      });
      dispatch(
        fetchArchiveSuccess(normalizePostEntities(response.body, getState()), {
          entityCount: getTotalResults(response.headers),
          pageSize: params.size,
          page,
        }),
      );
      return response.body;
    } catch (error) {
      dispatch(fetchArchiveFailure());
      return error.response;
    }
  };

export const fetchArchivePromisified = createPromisifiedAction(
  fetchArchive,
  () => null,
  (response) => response.status,
);
