import type { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import type { AppData, LikeStatusChangeEvent } from '../../viewer.app';

export type PostCounters = {
  id: string;
  likeCount: number;
  viewCount: number;
  commentsCount: number;
  isLiked: boolean;
};

export class CounterController {
  private state: PostCounters = {
    id: '',
    isLiked: false,
    likeCount: 0,
    viewCount: 0,
    commentsCount: 0,
  };

  private readonly ui = {
    likeCount: this.$w('#likeNumber'),
    viewsCount: this.$w('#viewsNumber'),
    commentsCount: this.$w('#commentsNumber'),
    likeButton: this.$w('#likeButtonBlack'),
  };

  private readonly facade = new BlocksWidgetFacade(this.flowAPI);

  constructor(
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private appData: AppData,
  ) {
    appData?.subjects.likeStatusChanged.subscribe((event) => {
      this.handleLikeChangeEvent(event.data);
    });

    this.ui.likeButton.onClick(async () => {
      await this.handleLikeClick();
    });
  }

  initialize = (model: PostPageRenderModel) => {
    this.setState({
      id: model.post.id,
      likeCount: model.post.likeCount || 0,
      viewCount: model.post.viewCount || 0,
      commentsCount: model.metadata.totalComments || 0,
      isLiked: model.metadata.isLiked || false,
    });
  };

  private setState(nextState: CounterController['state']) {
    this.state = nextState;

    this.ui.likeCount.text = String(nextState.likeCount);
    this.ui.viewsCount.text = String(nextState.viewCount);
    this.ui.commentsCount.text = String(nextState.commentsCount);
  }

  private async handleLikeClick() {
    if (!this.state.id) {
      throw new Error('No id provided');
    }

    const nextLikeState = !this.state.isLiked;
    const nextLikeCount = this.state.likeCount + (nextLikeState ? 1 : -1);

    if (nextLikeState) {
      await this.facade.likePost(this.state.id);
    } else {
      await this.facade.dislikePost(this.state.id);
    }

    this.setState({
      ...this.state,
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });

    this.appData?.subjects.likeStatusChanged.next({
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });
  }

  private handleLikeChangeEvent(payload: LikeStatusChangeEvent) {
    this.setState({
      ...this.state,
      likeCount: payload.likeCount,
      isLiked: payload.isLiked,
    });
  }
}
