import { AppData } from '../../../../viewer.app';
import {
  isEditor,
  isPreview,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { AppStore, RouteResolverFn } from '../../../common/types';
import { fetchLastPost } from '../../actions/fetch-last-post';
import { biOpenPostPageInEditor } from '../../actions/open-post-page-editor';
import { ROUTE_404 } from '../../constants/routes';
import { fetchRecentPostsAndComments } from '../fetch-data-for-post-widgets';

interface CreateLastPostPageRouterParams {
  store: AppStore;
  connections: any;
  appData: AppData;
}

export const createLastPostPageRouter =
  ({
    store,
    connections,
    appData,
  }: CreateLastPostPageRouterParams): RouteResolverFn =>
  async (_route, redirect) => {
    const state = store.getState();

    if (!(isEditor(state) || isPreview(state))) {
      return redirect(ROUTE_404);
    }

    if (!isSSR(state)) {
      store.dispatch(biOpenPostPageInEditor());
    }

    const lastPost = await store.dispatch(fetchLastPost());

    if (lastPost) {
      await fetchRecentPostsAndComments({
        store,
        post: lastPost,
        connections,
        appData,
      });
    }
  };
