import {
  getTPASettingsIsPostPageSplitEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import { AppData } from '../../../viewer.app';
import { fetchPostMetadata } from '../../common/actions/fetch-post-metadata';
import {
  getIsRecentPostsEnabled,
  getIsRelatedPostsEnabled,
} from '../../common/selectors/app-settings-selectors';
import { fetchProfileUrls } from '../../common/store/profile-urls/profile-urls-actions';
import { AppStore } from '../../common/types';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';
import { initCommentsWidget, initRelatedPostsWidget } from './connections';

export async function fetchRecentPostsAndComments({
  store,
  post,
  connections,
}: {
  store: AppStore;
  post: any;
  connections: any;
  appData: AppData;
}) {
  const state = store.getState();
  const parallelRequests: Promise<unknown>[] = [];
  const relatedPostsEnabled = getIsRelatedPostsEnabled(state) !== false;
  const recentPostsEnabled = getIsRecentPostsEnabled(state);
  const hasRelatedPosts = post.relatedPostIds && post.relatedPostIds.length > 0;

  const isPostPageSplitEnabled = getTPASettingsIsPostPageSplitEnabled(state);
  if (isPostPageSplitEnabled) {
    await Promise.all([
      initRelatedPostsWidget(connections, post),
      initCommentsWidget(connections, post._id),
    ]);
  }

  parallelRequests.push(store.dispatch(fetchPostMetadata(resolveId(post))));
  parallelRequests.push(store.dispatch(fetchProfileUrls()));

  if (recentPostsEnabled || (!hasRelatedPosts && relatedPostsEnabled)) {
    parallelRequests.push(store.dispatch(fetchRecentPosts(post)));
  }

  if (relatedPostsEnabled && hasRelatedPosts) {
    parallelRequests.push(store.dispatch(fetchRelatedPosts(post)));
  }

  await Promise.all(parallelRequests);
}
